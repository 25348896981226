/* You can add global styles to this file, and also import other style files */
@import 'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css';
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@import "../node_modules/angular-calendar/css/angular-calendar.css";

.nav-item:focus {
    outline: none;
}

hr {
    border-top: 1px solid lightgrey !important;
    width: 100%;
    display: block;
    margin: 1rem;
}

.bottle {
    display: inline-block;
    background-color: #0A5514;
    color: white;
    padding: 25px 10px;
    margin: 0 5px 0 5px;
    font-weight: 600;
    text-align: center;
}
.bottle-deleted{
    display:inline-block;
    background-color:#df4759;
    color:#fff;
    padding:25px 10px;
    margin:0 5px;
    font-weight:600;
    text-align:center
}
.current-bottle {
    display:inline-block;
    background-color:gray;
    color:#fff;
    padding:25px 10px;
    margin:0 5px;
    font-weight:600;
    text-align:center
}
.bottle-empty {
    display: inline-block;
    padding: 5px;
    margin: 0 5px 0 5px;
    font-weight: 600;
}

.striped {
    background: repeating-linear-gradient(
        45deg,
        #489100,
        #489100 10px,
        #346800 10px,
        #346800 20px
    );
}

.no-link {
    text-decoration: none !important;
    color: initial !important;
}


.list-group-striped li:nth-of-type(even) {
    background-color: rgba(0,0,0,.05);
}


.select-hidden-drowpdown .ng-select-container {
    border-radius: 4px !important;
}
.select-hidden-drowpdown > .ng-dropdown-panel {
    display: none !important;
}
.select-hidden-drowpdown .ng-arrow-wrapper {
    display: none !important;
}

/* Action button */
.dropdown-toggle { font-size: 1.2rem; }
.dropdown-toggle::after { content: none; }

/* SKELETON */
.p-component,
.p-component *,
.p-skeleton,
.skeleton * {
    box-sizing: border-box;
}

.p-skeleton {
    background-color: #e9ecef;
    border-radius: 3px;
}
.p-skeleton:after {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
}

.p-tooltip .p-tooltip-text {
    font-weight: 400;
    font-size: .875rem;
}

.p-tooltip {
    max-width: unset !important;
}

.tristate-boolean .p-checkbox-label {
    margin-bottom: 0;
}
  